/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { TextField, Select, Tooltip } from "@material-ui/core";
import { SingleSelector } from "../SelectComponents"
import { getProfileJobsByCatID } from '../../services/ProfileJobsService';
import { getJobFromProfile } from '../../services/RegisterJobService'
import { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import { Divider, FormControlLabel } from '@material-ui/core';
import Slider from '@mui/material/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import TestMethodTestsDLG from '../Dialogs/TestMethodTestsDLG'
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import SweepSetDetails from './SweepSetDetails';
import IconButton from '@mui/material/IconButton';
import { AddCircleOutline, AddCircle } from '@material-ui/icons';
import { getCategoriesDtv, getReasonsByCategoryId } from "../../services/CategoryService";
import { getClients } from "../../services/ClientsService";
import { getSampleTypes } from "../../services/SampleTypesService";
import { getDepartments} from "../../services/DepartmentsService";
import { getBySection } from "../../services/LookupService";

import { getSweepSetsByJobId, saveSweepSet } from '../../services/SweepSetService';




const ApplySweepJobProfile = (props) => {

    const { shown, sweepJob, setSweepJob, openEdit, setSaveBtnDisabled } = props;


    const { t } = useTranslation();

    // const [numSets, setNumSets] = useState(1);
    // const [numKey, setNumKey] = useState(1);

    const [allBrands, setAllBrands] = useState([]);
    const [allSubBrands, setAllSubBrands] = useState([]);
    const [allSizes, setAllSizes] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [sets, setSets] = useState([]);
    const [isSetsEmpty, setIsSetsEmpty] = useState(false);

    const LEFT = -1;
    const RIGHT = 1;


    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: "paper",
        },
        dialog: {
            width: "400px",
            display: "flex",
        },
        dialogBox: {
            alignItems: "center",
            display: "flex",
        },
        dialogText: {
            flex: "0.4",
        },
        dialogInput: {
            flex: "0.5",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            minWidth: 120,
            maxWidth: 300,
        },
        testContainer: {
            display: "flex",
            columnGap: "5%",
            flexWrap: "wrap",
            alignItems: "center",
            width: "100%",
            rowGap: "0.5rem",
        },
    }));

    const classes = useStyles();
    const sections = ["MARKET", "BRAND", "SECTOR", "SIZE", "SUBBRAND"];


    useEffect(() => {
        storeSweepJob();

    }, [sets]);

    useEffect(() => {
        if (shown) {
            if (sweepJob && sweepJob.id) {
                getSweepSetsByJobId(sweepJob.id).then((res) => {
                    console.log(res);
                    if (res.length > 0) {
                        setSets([...res])
                    }
                    else {
                        setIsSetsEmpty(true);
                    }
                });
            }
            


            getBySection(sections[1]).then((res) => {
                console.log("GOT BRAND: ", res);
                setAllBrands(res);

                setSaveBtnDisabled(false);
            });

            getBySection(sections[3]).then((res) => {
                console.log("GOT SIZE: ", res);
                setAllSizes(res);

                setSaveBtnDisabled(false);
            });

            getBySection(sections[4]).then((res) => {
                console.log("GOT SIZE: ", res);
                setAllSubBrands(res);

                setSaveBtnDisabled(false);
            });

            getCategoriesDtv().then((res) => {
                console.log("GOT categories: ", res);
                setAllCategories(res);

                setSaveBtnDisabled(false);
            });
        }
        // }
    }, [shown])

    function getUniqueKey() { // using timestamp to use as unique key for new sets (as they don't have an id yet)
        // Get the timestamp and convert 
        // it into alphanumeric input

        return Date.now().toString(36);
    }


    const addSet = (isPepsico) => {
        console.log("ADDING NEW SET")

        
        let newSet = {
            id: null,
            active: true,
            sweepJobId: sweepJob.id,
            brands: [],
            brandsOther: "",
            subBrands: [],
            subBrandsOther: "",
            packageType: null,
            sizes: [],
            sizesOther: "",
            sampleCount: 0,
            sequence: null,
            pepsico: isPepsico,
            uniqueKey: getUniqueKey()
        };


        setSets(sets => [...sets, newSet]);

    }


    const handleRemoveSet = (index) => {
            setSets(sets.filter((set, i) => (i !== index)));
    }

    const handleMoveSet = (index, direction) => {
        if (
            (direction === LEFT && index === 0) ||
            (direction === RIGHT && index === sets.length - 1)
        ) {
            return; // canot move outside of array
        }

        const newSets = [...sets];

        let temp = newSets[index + direction];

        newSets[index + direction] = newSets[index];

        newSets[index] = temp;
        
        setSets(newSets);

    }

    const handleSetChange = (index, newSet) => {
        const newSets = [...sets];
        newSets[index] = newSet;

        setSets(newSets);
    }

    const handleSaveSweepSet = async (index) => {
        let set = {...sets[index]}; 

        if (set.sampleCount > 0) {
            saveSweepSet(set).then((res) => {    
                set["samples"] = [...res.samples];
                
                set.id = res.id;

                handleSetChange(index, set);
            });
        }
        
    }

    const storeSweepJob = () => {
        let newSweepJob = {...sweepJob};

        newSweepJob["sweepSets"] = sets;
        setSweepJob(JSON.parse(JSON.stringify(newSweepJob)));
    }
    
    if (shown) return (
        <div>
            <h2>Sweep Job Sets</h2>
            <h3>Sweep Job Name: {sweepJob.name}</h3>
            <table  style={{width:"100%"}}>
                <tr>
                    <td style={{ padding: "20px", verticalAlign: "top", display: "flex", flexDirection: "column"}}>
                        
                        <div>
                            Own Sets:<span style={{ color: "red" }}>*</span>
                            <Tooltip title={"Add New Pepsi Set"}>
                                <IconButton onClick={() => addSet(true)}>
                                    <AddCircle />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={"Add New Competitor Set"}>
                                <IconButton onClick={() => addSet(false)}>
                                    <AddCircleOutline />
                                </IconButton>
                            </Tooltip>
                         </div>

                        {sets.length > 0 && 
                            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around"}}>
                                {sets.map((set, index) => (
                                    set.active ? <SweepSetDetails key={set.id && !set.uniqueKey ? set.id : set.uniqueKey} index={index} set={set} handleRemoveSet={handleRemoveSet} handleMoveSet={handleMoveSet} handleSetChange={handleSetChange} handleSaveSweepSet={handleSaveSweepSet} pepsico={set.pepsico} allBrands={allBrands} allSubBrands={allSubBrands} allSizes={allSizes} allCategories={allCategories} ></SweepSetDetails>
                                               : null
                                ))}
                            </div>
                            
                        }
                     
                    </td>
                   
                </tr>
            </table>

           
              
       
        </div>
    )

    else return <></>

}


export default ApplySweepJobProfile;


