/* eslint-disable  */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { addSampleType } from '../../services/SampleTypesService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getCategories } from '../../services/CategoryService';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { DataGrid } from '@material-ui/data-grid';
import { getSampleTypeTestSpecifications, updateSampleTypeTestSpecifications } from '../../services/TestsService'
import { Divider } from '@material-ui/core';
import { SingleSelector } from "../SelectComponents";
import { uploadFile } from '../../services/FileService'
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import IconButton from '@material-ui/core/IconButton';
import SaveChangesIcon from '@mui/icons-material/SaveAsOutlined';
import NavigateNextRounded from '@mui/icons-material/NavigateNextRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { uploadPreformDrawingFile, getSavedFile } from '../../services/FileService';
import { useTranslation } from 'react-i18next';
import ImgsViewer from "react-images-viewer";
import OKDialog from "./OKDialog";
import Box from '@material-ui/core/Box'
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const TabPanelAlt = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography style={{display:"flex", flexWrap:"wrap", flexDirection:"row"}}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

//const units = [{ "id": 1, "name": "g" }, { "id": 2, "name": "kg" }, { "id": 3, "name": "ounces" }, { "id": 4, "name": "lbs" }];

const units = ["g" , "kg" , "ounces" , "lbs" ];
const regions = ["ESSA","AMENA","APAC","LATAM","USA"];
const fillingTypes = ["CSD","NCB-P","NCB-NP","NCB-HF"];
const packageTypeShortCodes = ["PET", "CLO", "GLA", "CAN", "CAE"];

const roundTo2 = (num) => {
    return Math.round(num * 100) / 100
}


function SampleTypeDialog(props) {
    const { openDialog, openEdit, onDialogClose, selectedItems, text, updateTable } = props;
    const [sampleTypeName, setSampleTypeName] = useState("");
    const [description, setDescription] = useState("");
    const [code, setCode] = useState(fillingTypes[0]);
    const [alertBox, setAlertBox] = useState("");
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [category, setCategory] = useState({});
    const [design, setDesign] = useState("");
    const [weight, setWeight] = useState(0);
    const [unitOfWeight, setUnitOfWeight] = useState(units[0])
    // const [batchNumber, setBatchNumber] = useState("");
    const [bottleDrawingNumber, setBottleDrawingNumber] = useState("");
    const [bottleNeckFinish, setBottleNeckFinish] = useState("");
    const [bottleSize, setBottleSize] = useState("");
    // const [bottlePreformColorantReferenceNumber, setBottlePreformColorantReferenceNumber] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    // const [preformDrawingNumber, setPreformDrawingNumber] = useState("");
    
    // const [project, setProject] = useState("");
    const [toolCavitation, setToolCavitation] = useState("");
    const [applicationTorque, setApplicationTorque] = useState("");
    const [carbonationLevel, setCarbonationLevel] = useState("");
    const [closureSupplier, setClosureSupplier] = useState("");
    // const [fillerPaint, setFillerPaint] = useState("");
    const [product, setProduct] = useState("");
    const [shellMaterial, setShellMaterial] = useState("");
    const [slipAgent, setSlipAgent] = useState("");

    const [country, setCountry] = useState("");
    // const [beverageType, setBeverageType] = useState("");
    const [application, setApplication] = useState("");
    const [material, setMaterial] = useState("");
    const [returnable, setReturnable] = useState("");
    const [packageCategory, setPackageCategory] = useState("");
    const [pressure, setPressure] = useState("");
    const [tethered, setTethered] = useState("");
    const [plasticClosureType, setPlasticClosureType] = useState("");
    const [colour, setColour] = useState("");
    const [manufacturingProcess, setManufacturingProcess] = useState("");
    const [bodySize, setBodySize] = useState("");
    const [metalGauge, setMetalGauge] = useState("");
    const [neckDiameter, setNeckDiameter] = useState("");
    const [openingType, setOpeningType] = useState("");
    const [sizeDiameter, setSizeDiameter] = useState("");
    const [internalCoatingReference, setInternalCoatingReference] = useState("");

    const [selectedPackageTypeCode, setSelectedPackageTypeCode] = useState("");
    
    
    
    // const [accoladeProjectNumber, setAccoladeProjectNumber] = useState("");
    const [plantFacility, setPlantFacility] = useState("");
    const [region, setRegion] = useState(regions[0]);
    const [preformDrawing, setPreformDrawing] = useState();
    const [preformDrawingName, setPreformDrawingName] = useState("No image selected");
    const [preformSupplier, setPreformSupplier] = useState("");
    const [resin, setResin] = useState("");
    // const [otherAdditives, setOtherAdditives] = useState("");
    const [preformDrawingPath, setPreformDrawingPath] = useState("");
    const [isPreformDrawingPicked, setIsPreformDrawingPicked] = useState(false);
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [hideSpecsTable, setHideSpecsTable] = useState(true);
    const [rows, setRows] = useState([]);
    const [buttonVisibility, setButtonVisibility] = useState("visible");
    const [newSampleTypeId, setNewSampleTypeId] = useState();
    const { t } = useTranslation();
    const [viewImage, setViewImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(''); //for thumbnail if required
    const [imageForViewer, setImageForViewer] = useState([]);
    const [OKDialogState, setOKDialogState] = useState({
        shown: false, title: "", message: "", flavour: "success"
    })
    const [saving, setSaving] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    const getCheckbox = (cell) => {
        if (cell.row.na === null) cell.row.na = false
        return (
            <Tooltip title={<div style={{ fontSize: "small" }}>No max, min or niminal values.</div>} followCursor={true}>
                <div 
                    style={{ width: "100%", paddingRight: "15px", margin: "5px", cursor: "pointer" }}>
                    <Checkbox checked={cell.row.na}
                        onChange={(e) => {
                            cell.row.na = e.target.checked;
                            if (e.target.checked) {
                                cell.row.maxUnit = null;
                                cell.row.minUnit = null;
                                cell.row.nominalValue = null;
                                cell.row.minTolerance = 0.3;
                                cell.row.maxTolerance = 0.3;
                            }
                            setRows(JSON.parse(JSON.stringify(rows)))
                        }}>
                    </Checkbox>
                </div>
            </Tooltip>
        )
    }

    const getCellColor = (cell) => {
        if (cell.row.na || cell.value === null)  return "red"
        return "blue"
    }

    const getTooltip = (cell) => {
        return (
            <Tooltip title={<div style={{ fontSize: "small" }}>Double-click to edit value.<br />Enter to accept.</div>} followCursor={true}>
                <div onMouseOver={(e) => { e.target.style.background = '#dfdfdf'; }}
                    onMouseOut={(e) => { e.target.style.background = '#ffffff'; }}
                    style={{ width: "100%", textAlign: "right", color: getCellColor(cell) ,paddingRight: "15px", margin: "5px", cursor: "pointer" }}>
                    {cell.row.na || cell.value === null ? "......" : cell.value}
                </div>
            </Tooltip>
        )
    }

    const getTestNameTooltip = (cell) => {
        return (
            <Tooltip title={<div style={{ fontSize: "small" }}>{cell.value}</div>} followCursor={true}>
                <div onMouseOver={(e) => { e.target.style.background = '#dfdfdf'; }}
                    onMouseOut={(e) => { e.target.style.background = '#ffffff'; }}
                    style={{ width: "100%", color: getCellColor(cell) , cursor: "pointer" }}>
                    {cell.value === null ? "......" : cell.value}
                </div>
            </Tooltip>
        )
    }



    const columns = [
        { field: 'testName', renderCell: getTestNameTooltip, headerName: 'Test', headerAlign: 'left', flex: 0.9, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'minUnit', renderCell: getTooltip, headerName: 'Min.', width: 105, headerAlign: 'left', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'maxUnit', renderCell: getTooltip, headerName: 'Max.', width: 110, headerAlign: 'left', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'nominalValue', renderCell: getTooltip, headerName: 'Nominal', width: 130, headerAlign: 'left', type: 'number', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'minTolerance', renderCell: getTooltip, headerName: '% MinTol', width: 140, headerAlign: 'left', type: 'number', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'maxTolerance', renderCell: getTooltip, headerName: '% MaxTol', width: 140, headerAlign: 'left', type: 'number', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'minAverage', renderCell: getTooltip, headerName: 'Min Avg', width: 130, headerAlign: 'left', type: 'number', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'maxAverage', renderCell: getTooltip, headerName: 'Max Avg', width: 140, headerAlign: 'left', type: 'number', editable: true, cellClassName: 'border-roight', headerClassName: 'border-roight' },
        { field: 'na', renderCell: getCheckbox, headerName: 'N/a', headerAlign: 'left', width: 100, editable: false },
    ];

   

    const [pageSize, setPageSize] = useState(15);

    const handleSampleTypeNameChange = (event) => {
       
        setSampleTypeName(event.target.value);
        setAlertBox("");
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handlecodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleUnitOfWeightChange = (event) => {
        setUnitOfWeight(event.target.value);
    };

    const handleResinChange = (event) => {
        setResin(event.target.value);
    };

    // const handleOtherAdditivesChange = (event) => {
    //     setOtherAdditives(event.target.value);
    // };

    const handleDesignChange = (event) => {
        setDesign(event.target.value);
    };

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    };

    // const handleBatchNumberChange = (event) => {
    //     setBatchNumber(event.target.value);
    // };

    const handleBottleDrawingNumberChange = (event) => {
        setBottleDrawingNumber(event.target.value);
    };

    const handleBottleNeckFinishChange = (event) => {
        setBottleNeckFinish(event.target.value);
    };

    // const handleBottlePreformColorantReferenceNumberChange = (event) => {
    //     setBottlePreformColorantReferenceNumber(event.target.value);
    // };

    const handleBottleSizeChange = (event) => {
        setBottleSize(event.target.value);
    };

    // const handleAccoladeProjectNumberChange = (event) => {
    //     setAccoladeProjectNumber(event.target.value);
    // };

    const handlePlantFacilityChange = (event) => {
        setPlantFacility(event.target.value);
    };

    const handlePreformSupplierChange = (event) => {
        setPreformSupplier(event.target.value);
    };

    const handleRegionChange = (event) => {
        setRegion(event.target.value);
    };

    const handleManufacturerChange = (event) => {
        setManufacturer(event.target.value);
    };
/////////////////////////////////////////////////////////////
    // const handleProjectChange = (event) => {
    //     setProject(event.target.value);
    // };
    const handleToolCavitationChange = (event) => {
        setToolCavitation(event.target.value);
    };
    const handleApplicationTorqueChange = (event) => {
        setApplicationTorque(event.target.value);
    };
    const handleCarbonationLevelChange = (event) => {
        setCarbonationLevel(event.target.value);
    };
    const handleClosureSupplierChange = (event) => {
        setClosureSupplier(event.target.value);
    };
    // const handleFillerPaintChange = (event) => {
    //     setFillerPaint(event.target.value);
    // };
    const handleProductChange = (event) => {
        setProduct(event.target.value);
    };
    const handleShellMaterialChange = (event) => {
        setShellMaterial(event.target.value);
    };
    const handleSlipAgentChange = (event) => {
        setSlipAgent(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    // const handleBeverageTypeChange = (event) => {
    //     setBeverageType(event.target.value);
    // };
    
    const handleApplicationChange = (event) => {
        setApplication(event.target.value);
    };
    
    const handleMaterialChange = (event) => {
        setMaterial(event.target.value);
    };
    
    const handleReturnableChange = (event) => {
        setReturnable(event.target.value);
    };
    
    const handlePackageCategoryChange = (event) => {
        setPackageCategory(event.target.value);
    };
    
    const handlePressureChange = (event) => {
        setPressure(event.target.value);
    };
    
    const handleTetheredChange = (event) => {
        setTethered(event.target.value);
    };
    
    const handlePlasticClosureTypeChange = (event) => {
        setPlasticClosureType(event.target.value);
    };
    
    const handleColourChange = (event) => {
        setColour(event.target.value);
    };
    
    const handleManufacturingProcessChange = (event) => {
        setManufacturingProcess(event.target.value);
    };
    
    const handleBodySizeChange = (event) => {
        setBodySize(event.target.value);
    };
    
    const handleMetalGaugeChange = (event) => {
        setMetalGauge(event.target.value);
    };
    
    const handleNeckDiameterChange = (event) => {
        setNeckDiameter(event.target.value);
    };
    
    const handleOpeningTypeChange = (event) => {
        setOpeningType(event.target.value);
    };
    
    const handleSizeDiameterChange = (event) => {
        setSizeDiameter(event.target.value);
    };
    
    const handleInternalCoatingReferenceChange = (event) => {
        setInternalCoatingReference(event.target.value);
    };
///////////////////////////////////////

    const handlePreformDrawingChange = (event) => {
        setPreformDrawing(event.target.files[0]);
        setPreformDrawingName(event.target.files[0].name);
        setIsPreformDrawingPicked(true);
    };

    const handlepreformDrawingNameClick = (event) => {
        setViewImage(true);
    }

    const handleImageClose = (event) => {
        setViewImage(false);
    }

    const onBrowseClick = (event) => {
        event.target.value = null;
    };

    useEffect(() => {
        let types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
        if (isPreformDrawingPicked) {
            if (types.indexOf(preformDrawing.type.toLowerCase()) > -1) {
                const uploadPreformDrawing = async () => {
                    try {
                        await uploadPreformDrawingFile(preformDrawing).then((res) => {
                            if (res.key) setPreformDrawingPath(res.key);
                            if (res.image) {
                                // let base64ToString = Buffer.from(res.image, "base64").toString()
                                let image = `data:image/jpeg;base64,${res.image}`; //
                                let imageArray = [];
                                let imageForViewer = { src: image };
                                imageArray.push(imageForViewer);
                                setImageForViewer(imageArray);
                                setImageSrc(image);
                                setIsPreformDrawingPicked(false);
                            }
                        });
                    }
                    catch (err) {
                        setIsPreformDrawingPicked(false)
                    }
                }
                uploadPreformDrawing();
            }
            else {
                setIsPreformDrawingPicked(false)
                alert(t("image_type_error"));
            }
            
        }
    }, [isPreformDrawingPicked])



    // const handlePreformDrawingNumberChange = (event) => {
    //     setPreformDrawingNumber(event.target.value);
    // };


    useEffect(() => {
        if (openEdit) {
            setSampleTypeName(selectedItems.name)
            setDescription(selectedItems.description)
            setCode(selectedItems.code)
            setDesign(selectedItems.design)
            setWeight(selectedItems.weight)
            // setBatchNumber(selectedItems.batchNumber)
            setManufacturer(selectedItems.manufacturer)
            // setPreformDrawingNumber(selectedItems.preformDrawingNumber)
            setPreformDrawingPath(selectedItems.preformDrawingPath)
            setPreformDrawingName(selectedItems.diagramFilename)
            setResin(selectedItems.resin ? selectedItems.resin : "");
            setUnitOfWeight(selectedItems.unitOfWeight ? selectedItems.unitOfWeight: units[0]); 
            setButtonVisibility("hidden")
            getSpecifications(selectedItems.id);
            setHideSpecsTable(false)

            // setAccoladeProjectNumber(selectedItems.accolade)
            setRegion(selectedItems.region)
            setPlantFacility(selectedItems.plantFacility)
            setPreformSupplier(selectedItems.supplier)
            setBottleDrawingNumber(selectedItems.bottleDrawingNumber)
            setBottleNeckFinish(selectedItems.neckFinish)
            setBottleSize(selectedItems.bottleSize)
            // setBottlePreformColorantReferenceNumber(selectedItems.colorantReferenceNumber)
            // setOtherAdditives(selectedItems.additives)

            // setProject(selectedItems.project)
            setToolCavitation(selectedItems.toolCavitation)
            setApplicationTorque(selectedItems.applicationTorque)
            setCarbonationLevel(selectedItems.carbonationLevel)
            setClosureSupplier(selectedItems.closureSupplier)
            // setFillerPaint(selectedItems.fillerPaint)
            setProduct(selectedItems.product)
            setShellMaterial(selectedItems.shellMaterial)
            setSlipAgent(selectedItems.slipAgent)

            setCountry(selectedItems.country);
            // setBeverageType(selectedItems.beverageType);
            setApplication(selectedItems.application);
            setMaterial(selectedItems.material);
            setReturnable(selectedItems.returnable);
            setPackageCategory(selectedItems.packageCategory);
            setPressure(selectedItems.pressure);
            setTethered(selectedItems.tethered);
            setPlasticClosureType(selectedItems.plasticClosureType);
            setColour(selectedItems.colour);
            setManufacturingProcess(selectedItems.manufacturingProcess);
            setBodySize(selectedItems.bodySize);
            setMetalGauge(selectedItems.metalGauge);
            setNeckDiameter(selectedItems.neckDiameter);
            setOpeningType(selectedItems.openingType);
            setSizeDiameter(selectedItems.sizeDiameter);
            setInternalCoatingReference(selectedItems.internalCoatingReference);




            console.log("selected: " + selectedItems)

            let temp = categoryDetails.find((c) => { return c.id === selectedItems.category.id })
            if (temp) setCategory(temp);
            console.log('openEdit', openEdit)
        }
    }, [openEdit])

  

    useEffect(() => {
        if (openEdit) {
            const getImage = async () => {
                let data = await getSavedFile(selectedItems.preformDrawingPath);
                if (data) {
                    let image = `data:image/jpeg;base64,${data}`;
                    setImageSrc(image); //for thumbnail if required
                    /**
                     * for ImgsViewer
                     */
                    let imageArray = [];
                    let imageForViewer = { src: image };
                    imageArray.push(imageForViewer);
                    setImageForViewer(imageArray);
                }
            }
            getImage();
        }
    }, [openEdit]);

    useEffect(() => {
        if (openDialog && !openEdit) {
            let temp = categoryDetails.find((c) => { return c.name === "PET CSD" });
            if (temp) setCategory(temp);
        }
    }, [openDialog]);


    const getSpecifications = (id) => {

        getSampleTypeTestSpecifications(id).then((data) => {
           
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }

            data.forEach((r) => {
                if (r.minUnit !== null) r.minUnit = roundTo2(r.minUnit);
                if (r.maxUnit !== null) r.maxUnit = roundTo2(r.maxUnit);
                if (r.nominalValue !== null) r.nominalValue = roundTo2(r.nominalValue);
                if (r.minTolerance !== null) {
                    if (r.minTolerance === 0 && r.minUnit !== r.nominalValue) r.minTolerance = null;
                } 
                if (r.maxTolerance !== null) {
                    if (r.maxTolerance === 0 && r.maxUnit !== r.nominalValue) r.maxTolerance = null;
                }
            })

            //console.log(JSON.stringify(data, null, 2))
            setRows(data);
        })

    }

    

    useEffect(() => {
        getCategories(localStorage.getItem("language")).then((data) => {
            if (!(data instanceof Array)) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            setCategoryDetails(data);
        })
    }, []);


    const clearFields = () => {
        setSampleTypeName("");
        setDescription("");
        setCode(fillingTypes[0]);
        setAlertBox("");
        setCategory({});
        setDesign("");
        setWeight(0);
        // setBatchNumber("");
        setManufacturer("");
        setPreformDrawing("")
        // setPreformDrawingNumber("");
        setUnitOfWeight(units[0]);
        setHideSpecsTable(true);
        setButtonVisibility("visible")
        setRows([])
        setNewSampleTypeId()
        setPreformDrawing();
        setPreformDrawingName("No image selected")
        setIsPreformDrawingPicked(false);
        setPreformDrawingPath("");
        setViewImage(false);
        setImageForViewer([]);
        setImageSrc('');
        setResin("");

        // setAccoladeProjectNumber("")
        setRegion(regions[0]);
        setPlantFacility("")
        setPreformSupplier("")
        setBottleDrawingNumber("")
        setBottleNeckFinish("")
        setBottleSize("")
        // setBottlePreformColorantReferenceNumber("")
        // setOtherAdditives("")
        
        // setProject("")
        setToolCavitation("")
        setApplicationTorque("")
        setCarbonationLevel("")
        setClosureSupplier("")
        // setFillerPaint("")
        setProduct("")
        setShellMaterial("")
        setSlipAgent("")

        clearPackageTypeFields();

        setSelectedPackageTypeCode("");
    };

    const clearPackageTypeFields = () => {
        setCountry("");
        // setBeverageType("");
        setApplication("");
        setMaterial("");
        setReturnable("");
        setPackageCategory("");
        setPressure("");
        setTethered("");
        setPlasticClosureType("");
        setColour("");
        setManufacturingProcess("");
        setBodySize("");
        setMetalGauge("");
        setNeckDiameter("");
        setOpeningType("");
        setSizeDiameter("");
        setInternalCoatingReference("");
    };

    
    const handleAlertBox = (messageList) => {
        setSaving(false);
        setAlertBox(
            <div style={{ marginTop: "20px" }}>
            <Alert severity="warning" >
                <AlertTitle>Could not create Sample Type...</AlertTitle>
                <ul>{messageList.map((text, index) => <li key={index}>{text}</li>)} </ul>
                </Alert>
            </div>
        )
    };


    // If validation fails, scroll down to the alert box once it appears, so that it is visible on screen.
    useEffect(() => {
        if (alertBox && alertBox !== "") {
            document.getElementById("alertBox").scrollIntoView({ behavior: "smooth" });
        }
    }, [alertBox])

    useEffect(() => {
        if (Object.keys(category).length > 0) {
            let packageTypeCode = category.shortCode.substring(0, 3);
            
            switch (packageTypeCode) {
                case packageTypeShortCodes[0]:
                    setSelectedPackageTypeCode(packageTypeShortCodes[0]);
                    break;
                case packageTypeShortCodes[1]:
                    setSelectedPackageTypeCode(packageTypeShortCodes[1]);
                    break;
                case packageTypeShortCodes[2]:
                    setSelectedPackageTypeCode(packageTypeShortCodes[2]);
                    break;
                case packageTypeShortCodes[3]:
                    setSelectedPackageTypeCode(packageTypeShortCodes[3]);
                    break;
                case packageTypeShortCodes[4]:
                    setSelectedPackageTypeCode(packageTypeShortCodes[4]);
                    break;
                default:
                    setSelectedPackageTypeCode("");
                    break;
            }

        }
        
    }, [category])



    const closeAndUpdate = (res) => {
        setSaving(false);
        updateTable();
        onDialogClose(res);
        clearFields();
        setTabValue(0);
        setOKDialogState({
            shown: true,
            title: "Saved!",
            message: `Sample type has been saved.`,
            flavour: "success",
            callback: () => {  }
        });
    }

    const close = () => {
        setSaving(false);
        updateTable();
        onDialogClose();
        clearFields();
        setTabValue(0);
    }

    const handleSaveAndClose = async () => {
        let alertList = [];

        setSaving(true);

        // if (sampleTypeName.length === 0) alertList.push('You must provide a sample type name.');
        // if (bottleDrawingNumber.length === 0) alertList.push('You must provide a bottle drawing number.');
        // if (bottleNeckFinish.length === 0) alertList.push('You must provide a bottle neck finish.');
        // if (design.length === 0) alertList.push('You must provide a design.');
        // if (bottleSize.length === 0) alertList.push('You must provide a bottle size.');
        // if (manufacturer.length === 0) alertList.push('You must provide a manufacturer.');
        // if (weight === 0) alertList.push('You must provide a weight.');

        // if (Object.keys(category).length === 0) alertList.push("You must provide a package type.");


        console.log(alertList);
        if (alertList.length > 0) {
            handleAlertBox(alertList);
            return;
        } 

        else {
           
            let data = {
                name: sampleTypeName,
                category,
                code,
                description,
                design,
                weight,
                unitOfWeight,
                // batchNumber,
                manufacturer,
                // preformDrawingNumber,
                diagramFilename: preformDrawingName,
                preformDrawingPath,
                resin,
                // accolade: accoladeProjectNumber,
                plantFacility,
                supplier: preformSupplier,
                bottleSize,
                bottleDrawingNumber,
                neckFinish: bottleNeckFinish,
                // colorantReferenceNumber: bottlePreformColorantReferenceNumber,
                // additives: otherAdditives,
                region,
                
                
				// project:project,
				toolCavitation:toolCavitation,
				applicationTorque:applicationTorque,
				carbonationLevel:carbonationLevel,
				closureSupplier:closureSupplier,
				// fillerPaint:fillerPaint,
				product:product,
				shellMaterial:shellMaterial,
				slipAgent:slipAgent,

                country:country,
                // beverageType: beverageType,
                application:application,
                material:material,
                returnable:returnable,
                packageCategory:packageCategory,
                pressure:pressure,
                tethered:tethered,
                plasticClosureType:plasticClosureType,
                colour:colour,
                manufacturingProcess:manufacturingProcess,
                bodySize:bodySize,
                metalGauge:metalGauge,
                neckDiameter:neckDiameter,
                openingType:openingType,
                sizeDiameter:sizeDiameter,
                internalCoatingReference:internalCoatingReference


            }

            // sampleTypeName, category, description, code, design, weight, unitOfWeight, batchNumber, manufacturer, preformDrawingNumber, preformDrawingName, preformDrawingPath, selectedItems.id, resin
            // sampleTypeName, category, description, code, design, weight, unitOfWeight, batchNumber, manufacturer, preformDrawingNumber, preformDrawingName, preformDrawingPath, null, resin

            if (openEdit) {
                data.id = selectedItems.id
              
                addSampleType(data)
                    .then((result) => {
                        if (result.status === 200) {
                            console.log("Specs data:" + JSON.stringify(rows[1], null, 2))
                            updateSampleTypeTestSpecifications(rows).then((specsResult) => {
                                console.log("Specs result:" + JSON.stringify(specsResult[1], null, 2))
                                closeAndUpdate()
                            });
                        }
                    })
            }
            else if (newSampleTypeId) {
                data.id = newSampleTypeId
                addSampleType(data)
                    .then((result) => {
                        if (result.status === 200) {
                            updateSampleTypeTestSpecifications(rows).then((specsResult) => {
                                closeAndUpdate()
                            });
                        }
                    })
            }
            else {
                addSampleType(data).then(() => {
                    closeAndUpdate()
                })
            } 
           
        }

        close();
    };



    const handleSaveAndAddSpecs = async () => {
        let alertList = [];

        if (sampleTypeName.length === 0) alertList.push('You must provide a sample type name.');
        if (Object.keys(category).length === 0) alertList.push("Provide a package type");
        if (alertList.length !== 0) handleAlertBox(alertList);
        
        else {
            let data = {
                name: sampleTypeName,
                category,
                code,
                description,
                design,
                weight,
                unitOfWeight,
                // batchNumber,
                manufacturer,
                // preformDrawingNumber,
                diagramFilename: preformDrawingName,
                preformDrawingPath,
                resin,
                // accolade: accoladeProjectNumber,
                plantFacility,
                supplier: preformSupplier,
                bottleSize,
                bottleDrawingNumber,
                neckFinish: bottleNeckFinish,
                // colorantReferenceNumber: bottlePreformColorantReferenceNumber,
                // additives: otherAdditives,
                region
            }
            let res = await addSampleType(data)
            handleStatusCode(res);
          
        }
    };

    const handleStatusCode = (event) => {
        console.log("!!!!!!!!!!!!!!!!!!!!", event.status)
        switch (event.status) {
            case 200:
                setNewSampleTypeId(event.data.id);
                setHideSpecsTable(false);
                setOKDialogState({
                    shown: true,
                    title: "Saved!",
                    message: `Sample type '${event.data.name}' has been saved. You may now add Test Specifications for it.`,
                    flavour: "success",
                    callback: () => { setTabValue(1)}
                });
                break;
            default:
                setOKDialogState({
                    shown: true,
                    title: "Failure",
                    message: `failed to save, try later`,
                    flavour: "error",
                    // callback: revisitPage1
                });
                break;

        }
    };

   

    useEffect(() => {
        if (newSampleTypeId) {
            setButtonVisibility("hidden")
            getSpecifications(newSampleTypeId)  // sets the rows for the specs table
        }
    }, [newSampleTypeId]);



    const handleCellEditCommit = (editedCell) => {
        
        var temp = parseFloat("" + editedCell.value)
        if (isNaN(temp))  editedCell.value = null;
        else editedCell.value = temp

        temp = null;
        const updatedRows = rows.map(row => {
            if (row.id === editedCell.id) {
               
                if (editedCell.field !== "na" && (!row.na)) doCalc(editedCell, row);
                temp = { ...row, [editedCell.field]: editedCell.value }
                return temp
            }
            else return row
        });
        console.log(JSON.stringify(temp, null, 2))

        if (temp.na) return;

        if (temp.minUnit != null && temp.maxUnit != null && temp.minUnit > temp.maxUnit) {
            alert("Max. value can't be less than the min value!")
            setRows(JSON.parse(JSON.stringify(rows)))
            return;
        }

        if (temp.nominalValue != null && temp.minUnit != null && temp.minUnit > temp.nominalValue) {
            alert("Min. value can't be greater than the nominal value!")
            setRows(JSON.parse(JSON.stringify(rows)))
            return;
        }
        if (temp.nominalValue != null && temp.minUnit != null && temp.minUnit > temp.nominalValue) {
            alert("Max. value can't be less than the nominal value!")
            setRows(JSON.parse(JSON.stringify(rows)))
            return;
        }

       
        setRows(updatedRows)
    }




    const doCalc = (editedCell, row) => {

        console.log("-----------> " + editedCell.field)

        if (editedCell.field === "minUnit") {
            if (row.nominalValue != null && editedCell.value <= row.nominalValue) {
                if (row.maxUnit != null && editedCell.value > row.maxUnit) {}
                else row.minTolerance = (row.nominalValue - editedCell.value) / 100
            }
        }

        if (editedCell.field === "maxUnit") {
            if (row.nominalValue != null && editedCell.value >= row.nominalValue) {
                if (row.minUnit != null && editedCell.value < row.minUnit) {}
                else row.maxTolerance = (editedCell.value - row.nominalValue) / 100;
            }
        }

        if (editedCell.field === "minTolerance") {
            if (row.nominalValue != null) {
                var tol = (editedCell.value / 100) * row.nominalValue;
                row.minUnit = row.nominalValue - tol;
            }
        }

        if (editedCell.field === "maxTolerance") {
            if (row.nominalValue != null) {
                
                var tol = (editedCell.value / 100) * row.nominalValue;
                row.maxUnit = row.nominalValue + tol;
            }
        }

        if (editedCell.field === "nominalValue") {
            console.log("edited nom val:" + editedCell.value)
            var nom = editedCell.value;
            

            var minTol = (row.minTolerance / 100) * nom;
            var maxTol = (row.maxTolerance / 100) * nom;
            row.minUnit = nom - minTol;
            row.maxUnit = nom + maxTol;
            
        }


        if (row.minUnit !== null) {
            row.minUnit = roundTo2(row.minUnit);
        }
        if (row.maxUnit !== null) {
            row.maxUnit = roundTo2(row.maxUnit);
        }
        editedCell.value = roundTo2(editedCell.value);
        
        
    }



    const handleCancel = () => {
        updateTable();
        onDialogClose();
        clearFields();
        setTabValue(0);
    };

    useEffect(() => {

        
    }, [rows])

    const useStyles = makeStyles((theme) => ({
        root: {
            flex: 1,
            backgroundColor: 'paper',
        },
        dialog: {
            width: '400px',
            display: 'flex'
        },
        dialogBox: {
            display: 'flex',
            alignItems: 'center'
        },
        dialogBox2: {
            display: 'flex',
            alignItems: 'center',
            flex: '0 50%'
        },
        dialogText: {
            flex: '0.4'
        },
        dialogInput: {
            flex: '0.5',

        },
        formControl: {
            margin: theme.spacing(2),
            flex: '0.5',
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        weightRow: {
            display: 'flex',
            flexWrap: 'wrap'
        },
    }));

    const classes = useStyles();
    const theme = useTheme();



    return (
        <div className={classes.root}>
            <Dialog open={openDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="xl">  {/* sm, xs, md, lg, xl*/}
                <DialogTitle id="form-dialog-title">{(openEdit ? t("edit_label") : t("add"))} {t("sample_type_label")} </DialogTitle>
                <DialogContent>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} textColor="secondary" indicatorColor="secondary" onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Sample Type Details" />
                            {hideSpecsTable === false && <Tab label="Test Specifications" />}
                        </Tabs>
                    </Box>             

                   
                    {/* Sample type fields tab ================================================================================================ */}

                    <TabPanelAlt value={tabValue} index={0}>
                       
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("sample_type_name_label")}
                            </Typography>

                            <TextField value={sampleTypeName} on="true" onChange={handleSampleTypeNameChange} className={classes.dialogInput} autoFocus margin="dense" id="name" type="input" variant="outlined" required />
                        </div>
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("beverage_type_field_label")}
                            </Typography>

                            {/* <TextField value={code} onChange={handlecodeChange} className={classes.dialogInput} style={{ maxWidth: '20%' }} margin="dense" id="name" type="input" variant="outlined" required /> */}
                            <FormControl className={clsx(classes.dialogInput)}>
                                <Select
                                    displayEmpty
                                    value={code}
                                    onChange={handlecodeChange}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        return (<Typography>{selected}</Typography>)
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ "aria-label": "Without label" }}>

                                    {fillingTypes.map((type, idx) => (
                                        <MenuItem key={idx} value={type} >
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>


                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("category_label")}
                            </Typography>

                            <FormControl variant='outlined' className={clsx(classes.formControl, classes.noLabel, classes.dialogInput)}>
                                <SingleSelector placeholder={"Start typing to find package type..."} width={300} value={{ value: category, label: category.name }}
                                    options={categoryDetails.map((c) => ({ value: c, label: c.name }))}
                                    handleSelect={(e) => { setCategory(e.value); setAlertBox(""); clearPackageTypeFields();}}>
                                </SingleSelector>
                            </FormControl>

                        </div>
                        <div className={classes.dialogBox2}>
                            <Typography className={classes.dialogText} variant="body1">
                                {t("sector_label")}
                            </Typography>
                            <FormControl className={clsx(classes.dialogInput)}>
                                <Select
                                    displayEmpty
                                    value={region}
                                    onChange={handleRegionChange}
                                    input={<Input />}
                                    renderValue={(selected) => {
                                        return (<Typography>{selected}</Typography>)
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ "aria-label": "Without label" }}>

                                    {regions.map((region, idx) => (
                                        <MenuItem key={idx} value={region} >
                                            {region}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("filler_plant_label")}
                            </Typography>
                            <TextField value={plantFacility} on="true" onChange={handlePlantFacilityChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("description_field_label")}
                            </Typography>

                            <TextField value={description} onChange={handleDescriptionChange} className={classes.dialogInput} placeholder={t("enter_description_placeholder")} multiline rows={3} margin="dense" id="description" type="input" variant="outlined" required />
                        </div>
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("design_label")}
                            </Typography>
                            <TextField value={design} on="true" onChange={handleDesignChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>
                        <div className={classes.weightRow} style={{width:"50%"}}>
                            <div className={classes.dialogBox2} style={{ flex: '50%', display: 'flex' }}>
                                <Typography variant="body1" className={classes.dialogText} style={{ flex: '60%' }}>
                                    {t("weight_label")}
                                </Typography>
                                <TextField value={weight} on="true" onChange={handleWeightChange} className={classes.dialogInput} style={{ flex: '40%' }} margin="dense" id="name" type="number" variant="outlined" />
                            </div>
                            <div className={classes.dialogBox2} style={{ flex: '50%', display: 'flex' }}>
                                <Typography className={classes.dialogText} variant="body1" style={{ flex: '20%', marginLeft: '2em' }}>
                                    {t("units_label")}
                                </Typography>
                                <FormControl className={clsx(classes.formControl, classes.noLabel, classes.dialogInput)} style={{ flex: '80%' }}>
                                    <Select
                                        displayEmpty
                                        value={unitOfWeight}
                                        onChange={handleUnitOfWeightChange}
                                        input={<Input />}
                                        renderValue={(selected) => {
                                            return (<Typography>{selected}</Typography>)
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ "aria-label": "Without label" }} style={{ maxWidth: '70%' }}>

                                        {units.map((unit, idx) => (
                                            <MenuItem key={idx} value={unit} >
                                                {unit}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("Resin")}
                            </Typography>
                            <TextField value={resin} on="true" onChange={handleResinChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>
                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("other_additives_label")}
                            </Typography>
                            <TextField value={otherAdditives} on="true" onChange={handleOtherAdditivesChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}
                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("batch_number_label")}
                            </Typography>
                            <TextField value={batchNumber} on="true" onChange={handleBatchNumberChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("bottle_drawing_number_label")}
                            </Typography>
                            <TextField value={bottleDrawingNumber} on="true" onChange={handleBottleDrawingNumberChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>
                        {/* {selectedPackageTypeCode != packageTypeShortCodes[3] && selectedPackageTypeCode != packageTypeShortCodes[4]
                            ?   <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("bottle_neck_finish_label")}*
                                    </Typography>
                                    <TextField value={bottleNeckFinish} on="true" onChange={handleBottleNeckFinishChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>
                            :   null
                        } */}
                        
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("net_contents_label")}
                            </Typography>
                            <TextField value={bottleSize} on="true" onChange={handleBottleSizeChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>
                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("bottle_preform_colorant_reference_number_label")}
                            </Typography>
                            <TextField value={bottlePreformColorantReferenceNumber} on="true" onChange={handleBottlePreformColorantReferenceNumberChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}
                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("vendor_label")}*
                            </Typography>
                            <TextField value={manufacturer} on="true" onChange={handleManufacturerChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}
                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("accolade_project_number_label")}
                            </Typography>
                            <TextField value={accoladeProjectNumber} on="true" onChange={handleAccoladeProjectNumberChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}
                        <div className={classes.dialogBox2} style={{ display: 'flex' }}>
                            <Typography variant="body1" className={classes.dialogText} style={{ flex: imageSrc !== '' ? '40%' : '80%' }}>
                                {t("preform_drawing_label")}
                            </Typography>
                            {imageSrc !== '' && <Tooltip title="Click to view" >
                                <div style={{ display: 'flex', flex: '40%' }} onClick={handlepreformDrawingNameClick} >
                                    <label style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '60%', maxWidth: '60%' }} >{preformDrawingName}</label>
                                    <img src={imageSrc} alt={preformDrawingName} style={{ flex: '40%', maxWidth: '30%' }}></img>
                                </div>
                            </Tooltip>}
                            <ImgsViewer
                                imgs={imageForViewer}
                                isOpen={viewImage}
                                onClose={handleImageClose}
                            />
                            <div className={classes.dialogBox2} style={{ display: 'flex', flex: '20%' }}>
                                <Button variant="contained" component="label" style={{ flex: '100%', maxWidth: '50%' }}>
                                    {t("browse_label")}
                                    <input type="file" accept="image/*" hidden className={classes.dialogText} onChange={handlePreformDrawingChange} onClick={onBrowseClick} id="preformDrawingName" />
                                </Button>
                            </div>
                        </div>
                        {/* {selectedPackageTypeCode == packageTypeShortCodes[0] && 
                            <div className={classes.dialogBox2}>
                                <Typography variant="body1" className={classes.dialogText}>
                                    {t("preform_supplier_label")}
                                </Typography>
                                <TextField value={preformSupplier} on="true" onChange={handlePreformSupplierChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                            </div>
                        } */}
                        
                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("preform_drawing_number_label")}
                            </Typography>
                            <TextField value={preformDrawingNumber} on="true" onChange={handlePreformDrawingNumberChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}
                       

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("project")}
                            </Typography>
                            <TextField value={project} on="true" onChange={handleProjectChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}

                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("toolCavitation")}
                            </Typography>
                            <TextField value={toolCavitation} on="true" onChange={handleToolCavitationChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("applicationTorque")}
                            </Typography>
                            <TextField value={applicationTorque} on="true" onChange={handleApplicationTorqueChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}

                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("carbonationLevel")}
                            </Typography>
                            <TextField value={carbonationLevel} on="true" onChange={handleCarbonationLevelChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("closureSupplier")}
                            </Typography>
                            <TextField value={closureSupplier} on="true" onChange={handleClosureSupplierChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("fillerPaint")}
                            </Typography>
                            <TextField value={fillerPaint} on="true" onChange={handleFillerPaintChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}

                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("brand")}
                            </Typography>
                            <TextField value={product} on="true" onChange={handleProductChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div>

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("shellMaterial")}
                            </Typography>
                            <TextField value={shellMaterial} on="true" onChange={handleShellMaterialChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("slipAgent")}
                            </Typography>
                            <TextField value={slipAgent} on="true" onChange={handleSlipAgentChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                        </div> */}

                        {/* ----------------------------------------------------------- */}
                        <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("Country")}
                            </Typography>
                            <TextField value={country} on="true" onChange={handleCountryChange} className={classes.dialogInput} margin="dense" id="country" type="input" variant="outlined" />
                        </div>

                        {/* <div className={classes.dialogBox2}>
                            <Typography variant="body1" className={classes.dialogText}>
                                {t("Beverage Type")}
                            </Typography>
                            <TextField value={beverageType} on="true" onChange={handleBeverageTypeChange} className={classes.dialogInput} margin="dense" id="beverageType" type="input" variant="outlined" />
                        </div> */}
                        
                        {selectedPackageTypeCode == packageTypeShortCodes[0] &&
                            <React.Fragment>
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("vendor_label")}
                                    </Typography>
                                    <TextField value={manufacturer} on="true" onChange={handleManufacturerChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("finish_type_label")}
                                    </Typography>
                                    <TextField value={bottleNeckFinish} on="true" onChange={handleBottleNeckFinishChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("preform_supplier_label")}
                                    </Typography>
                                    <TextField value={preformSupplier} on="true" onChange={handlePreformSupplierChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>
                                
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Application")}
                                    </Typography>
                                    <TextField value={application} on="true" onChange={handleApplicationChange} className={classes.dialogInput} margin="dense" id="application" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Material")}
                                    </Typography>
                                    <TextField value={material} on="true" onChange={handleMaterialChange} className={classes.dialogInput} margin="dense" id="material" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Returnable/Non Returnable")}
                                    </Typography>
                                    <TextField value={returnable} on="true" onChange={handleReturnableChange} className={classes.dialogInput} margin="dense" id="returnable" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("PackageCategory")}
                                    </Typography>
                                    <TextField value={packageCategory} on="true" onChange={handlePackageCategoryChange} className={classes.dialogInput} margin="dense" id="packageCategory" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Pressure")}
                                    </Typography>
                                    <TextField value={pressure} on="true" onChange={handlePressureChange} className={classes.dialogInput} margin="dense" id="pressure" type="input" variant="outlined" />
                                </div>
                            </React.Fragment>
                            
                        }

                        {selectedPackageTypeCode == packageTypeShortCodes[1] &&
                            <React.Fragment>
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("applicationTorque")}
                                    </Typography>
                                    <TextField value={applicationTorque} on="true" onChange={handleApplicationTorqueChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("closureSupplier")}
                                    </Typography>
                                    <TextField value={closureSupplier} on="true" onChange={handleClosureSupplierChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("shellMaterial")}
                                    </Typography>
                                    <TextField value={shellMaterial} on="true" onChange={handleShellMaterialChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("slipAgent")}
                                    </Typography>
                                    <TextField value={slipAgent} on="true" onChange={handleSlipAgentChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Tethered/Non Tethered")}
                                    </Typography>
                                    <TextField value={tethered} on="true" onChange={handleTetheredChange} className={classes.dialogInput} margin="dense" id="tethered" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("finish_type_label")}
                                    </Typography>
                                    <TextField value={bottleNeckFinish} on="true" onChange={handleBottleNeckFinishChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Application")}
                                    </Typography>
                                    <TextField value={application} on="true" onChange={handleApplicationChange} className={classes.dialogInput} margin="dense" id="application" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Plastic Closure Type")}
                                    </Typography>
                                    <TextField value={plasticClosureType} on="true" onChange={handlePlasticClosureTypeChange} className={classes.dialogInput} margin="dense" id="plasticClosureType" type="input" variant="outlined" />
                                </div>
                            </React.Fragment>
                        }

                        {selectedPackageTypeCode == packageTypeShortCodes[2] &&
                            <React.Fragment>
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("vendor_label")}
                                    </Typography>
                                    <TextField value={manufacturer} on="true" onChange={handleManufacturerChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("finish_type_label")}
                                    </Typography>
                                    <TextField value={bottleNeckFinish} on="true" onChange={handleBottleNeckFinishChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Colour")}
                                    </Typography>
                                    <TextField value={colour} on="true" onChange={handleColourChange} className={classes.dialogInput} margin="dense" id="colour" type="input" variant="outlined" />
                                </div>

                                {selectedPackageTypeCode != packageTypeShortCodes[1] &&
                                    <div className={classes.dialogBox2}>
                                        <Typography variant="body1" className={classes.dialogText}>
                                            {t("Manufacturing Process")}
                                        </Typography>
                                        <TextField value={manufacturingProcess} on="true" onChange={handleManufacturingProcessChange} className={classes.dialogInput} margin="dense" id="manufacturingProcess" type="input" variant="outlined" />
                                    </div>
                                }
                                

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Returnable/Non Returnable")}
                                    </Typography>
                                    <TextField value={returnable} on="true" onChange={handleReturnableChange} className={classes.dialogInput} margin="dense" id="returnable" type="input" variant="outlined" />
                                </div>
                            </React.Fragment>
                        }

                        {selectedPackageTypeCode == packageTypeShortCodes[3] &&
                            <React.Fragment>
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("vendor_label")}
                                    </Typography>
                                    <TextField value={manufacturer} on="true" onChange={handleManufacturerChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Body Size")}
                                    </Typography>
                                    <TextField value={bodySize} on="true" onChange={handleBodySizeChange} className={classes.dialogInput} margin="dense" id="bodySize" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Metal Gauge")}
                                    </Typography>
                                    <TextField value={metalGauge} on="true" onChange={handleMetalGaugeChange} className={classes.dialogInput} margin="dense" id="metalGauge" type="input" variant="outlined" />
                                </div>
                                
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Neck Diameter")}
                                    </Typography>
                                    <TextField value={neckDiameter} on="true" onChange={handleNeckDiameterChange} className={classes.dialogInput} margin="dense" id="neckDiameter" type="input" variant="outlined" />
                                </div>
                            </React.Fragment>
                        }

                        {selectedPackageTypeCode == packageTypeShortCodes[4] &&
                            <React.Fragment>
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("vendor_label")}
                                    </Typography>
                                    <TextField value={manufacturer} on="true" onChange={handleManufacturerChange} className={classes.dialogInput} margin="dense" id="name" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Metal Gauge")}
                                    </Typography>
                                    <TextField value={metalGauge} on="true" onChange={handleMetalGaugeChange} className={classes.dialogInput} margin="dense" id="metalGauge" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Opening Type")}
                                    </Typography>
                                    <TextField value={openingType} on="true" onChange={handleOpeningTypeChange} className={classes.dialogInput} margin="dense" id="openingType" type="input" variant="outlined" />
                                </div>
                                
                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Size Diameter")}
                                    </Typography>
                                    <TextField value={sizeDiameter} on="true" onChange={handleSizeDiameterChange} className={classes.dialogInput} margin="dense" id="sizeDiameter" type="input" variant="outlined" />
                                </div>

                                <div className={classes.dialogBox2}>
                                    <Typography variant="body1" className={classes.dialogText}>
                                        {t("Internal Coating Reference")}
                                    </Typography>
                                    <TextField value={internalCoatingReference} on="true" onChange={handleInternalCoatingReferenceChange} className={classes.dialogInput} margin="dense" id="internalCoatingReference" type="input" variant="outlined" />
                                </div>
                            </React.Fragment>
                        }
                        
                       
                    </TabPanelAlt>


                    {/* Specs table tab ================================================================================================ */}
                    <TabPanel value={tabValue} index={1}>
                        <Box hidden={hideSpecsTable} style={{ flex: '100%', height: "66vh" }}
                            sx={{
                                '& .table-border': {
                                    border: '2px solid',
                                    borderColor: 'rgba(63, 81, 181, 0.55)',
                                },
                                '& .border-roight': {
                                    borderRight: '1px solid rgba(63, 81, 181, 0.55)',
                                },
                            }}>

                           
                            <span>Toggle the <b>N/a</b> checkbox to reset row fields to default values. <b>Tolerance</b> values default to 0.3% and only matter when the <b>Nominal</b> value is set.<br/>All other fields default to null ( no value ).</span>
                            <DataGrid className='table-border'
                                rows={rows}
                                columns={columns}
                                onCellEditCommit={handleCellEditCommit}
                            />
                        </Box>
                       
                    </TabPanel>

                    {/*  ================================================================================================ */}


                    {/* Form validation alert box. On failed validation, this component will become visible and the page will scroll down to it.... */}
                    <div style={{ marginTop: "70px" }} id="alertBox">{alertBox}</div>

                </DialogContent>


                <DialogActions>

                    {saving ? t('sample_type_saving') : ""}
                    <Tooltip title="Save & Close">
                        <IconButton disabled={saving}  onClick={handleSaveAndClose} color="primary">
                            {openEdit ? <SaveChangesIcon /> : <SaveIcon />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Next-Add Specs">
                        <IconButton disabled={saving} style={{ visibility: buttonVisibility, marginTop: buttonVisibility === "hidden" ? "-50em" : "0em" }}
                            onClick={handleSaveAndAddSpecs} color="primary">
                            <NavigateNextRounded />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Cancel">
                        <IconButton onClick={handleCancel}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>

                </DialogActions>
            </Dialog>

            <OKDialog
                show={OKDialogState.shown}
                title={OKDialogState.title}
                message={OKDialogState.message}
                flavour={OKDialogState.flavour}
                callback={
                    (res) => {
                        let callback = OKDialogState.callback;
                        setOKDialogState({ shown: false });
                        if (callback) callback(res);
                    }
                }
            />
        </div>
    )
}

export default SampleTypeDialog